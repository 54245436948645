.feedback-content {
  @apply bg-sand rounded-xl;
  min-height: 350px;
  max-width: 800px;
  padding: 20px;
  margin: 20px auto;
  font-size: 26px;
  color: #165275;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 40px;

  h2 {
    font-size: 26px;
  }

  .button {
    border-radius: 1.5rem;
    width: 300px;
    align-self: center;
    font-size: 26px;
  }

  &__link {
    font-size: 20px;
    text-decoration: underline;
  }
}
