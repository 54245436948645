.coaches-container {
  @apply w-full m-auto bg-sand rounded-xl p-4
}

.coaches-coach-profile {
  @apply rounded-2xl p-4 mb-2 bg-white border border-orange-pensive;
}

.coaches-coach-profile__image {
  @apply mr-1 mb-1 float-left;

  width: 100px;
}

.coaches-coach-profile__name {
  @apply mt-1 mb-2;
}

.coaches-coach-profile__cta {
  @apply mt-4 text-right;
}

@screen md {
  .coaches-container {
    @apply w-5/6
  }

  .coaches-coach-profile {
    @apply grid gap-2;

    grid-template-areas: 'image name'
    'image bio'
    'image cta';
    grid-template-columns: 200px 1fr;
  }

  .coaches-coach-profile__image {
    float: none;
    grid-area: image;
    width: auto;
  }

  .coaches-coach-profile__name {
    @apply my-0;

    grid-area: name;
  }

  .coaches-coach-profile__bio {
    grid-area: bio;
  }

  .coaches-coach-profile__cta {
    grid-area: cta;
    justify-self: flex-end;
  }
}