.context-menu {
  cursor: pointer;
  position: relative;
  font-size: 16px;

  .popover {
    @apply rounded-lg bg-white text-orange-vibrant;

    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.12);
    display: none;
    left: calc(-210px);
    position: absolute;
    top: -26px;
    width: 200px;
    z-index: 10;

    li {
      margin: 10px 0;
      position: relative;
      padding-left: 15px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        background: #F5F2ED;
        width: calc(100% - 4px);
        height: 1px;
        top: calc(100% + 2px);
        left: 0;
      }

      &:last-child:before {
        display: none;
      }
    }
  }

  &.--open {
    .popover {
      display: block;
    }
  }
}
