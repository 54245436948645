.dashboard-sidebar-menu {
  a {
    @apply px-2 py-1 block rounded;
  }

  .selected {
    @apply bg-orange-inspired;
  }
}

.bb-chart-lines {
  fill: none;
}
