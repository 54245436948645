$black: #000;
$light-grey: #F3F3F3;
$dark-grey: #C4C4C4;

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.sign-up__form {
  padding-top: 60px;
}

.form-prompt {
  font-size: 28px;
  font-weight: 700;
}

.email {
  padding: 15px;
  font-size: 28px;
  max-width: 360px;
  width: 100%;
  margin-right: 20px;
}

.submit {
  border-radius: 48px;
  border: none;
  padding: 16px 46px;
  cursor: pointer;
}

.narrow {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  label {
    font-size: 18px;
    font-weight: 400;
    display: block;
  }

  fieldset {
    margin-bottom: 30px;
  }

  .submit {
    width: 100%;
    border-radius: 10px;
  }
}

.medium {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.input {
  font-size: 16px;
  margin: 2px 0;
  padding: 10px;
  width: 100%;
  border: solid 1px rgba(0,0,0,0.1);

  &.--code {
    text-align: center;
  }
}

.remember-me__checkbox {
  @apply mr-1 relative;

  bottom: -2px;
  vertical-align: top;
}

.intro {
  font-size: 18px;
  font-weight: 400;
}

.office-list {
  li {
    background: $light-grey;
    margin-bottom: 15px;
    border-radius: 10px;

    label {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;
      padding: 20px;
      cursor: pointer;
      user-select: none;
    }

    input[type="checkbox"] {
      display: inline-block;
    }

    small {
      display: block;
    }
  }
}

.merge-accounts {
  .submit-with-feedback {
    transition: all 0.3s;
  }
}

.--submitted {
  .submit-with-feedback {
    color: transparent;
    opacity: 0.6;
    transform: scale(0.9);
  }

  svg.icon-spinner {
    display: block;
    opacity: 1;
  }
}

@keyframes rotate {
  0% { transform: translate(-50%, -50%) scale(1) rotate(0); }
  100% { transform: translate(-50%, -50%) scale(1) rotate(360deg); }
}

svg.icon-spinner {
  animation: rotate .5s linear infinite;
  display: none;
  opacity: 0;
  transform-origin: center;
  transition: all 0.2s;
}

.submit-with-feedback__feedback {}

.list.--checkmark {
  li {
    padding-left: 40px;
    position: relative;

    &:before {
      display: block;
      content: '';
      position: absolute;
      background-image: url('../images/list-tick.svg');
      width: 40px;
      left: 0;
      height: 100%;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.account-menu {
  li {
    align-content: center;
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 27px 1fr;
    margin-bottom: 20px;

    a {
      line-height: 27px;
      position: relative;
      top: 2px;
    }

    svg {
      fill: rgb(31, 58, 94);
      height: 27px;
      width: 27px;
    }

    &.--current {
      @apply text-orange-vibrant;

      svg {
        fill: #F27D4D;
      }
    }
  }
}

.disable-alerts {
  @apply flex items-center;

  svg {
    fill: #165275;
    margin-right: 3px;
    width: 10px;
  }
}

.enable-alerts {
  @apply flex items-center;

  svg {
    fill: #fff;
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }
}

.enabled-alerts {
  svg {
    margin-right: 5px;
    width: 16px;
  }
}

#single-sign-on {
  #buttons {
    .oauth-button {
      img {
        @apply w-48;
      }
    }
  }
}
