@import 'app/assets/stylesheets/_vars';

.coach-selection {
  .wildcard-text {
    @apply col-span-2;
    font-weight: unset;
  }
}

.coach-selection.coach-recommendation {
  @apply mx-auto max-w-5xl;

  @media only screen and (max-width: $tablet-width) {
    width: 100%;
  }
}

.load-more-button {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.load-more-button:hover {
  text-decoration: underline;
}
