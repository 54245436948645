.coach-profile-card-simple {
  @apply mb-8 md:mb-0 bg-white mb-4 p-3 md:p-4 rounded-2xl w-full h-full shadow-md overflow-hidden border border-grey-5 hover:shadow-none transition duration-500 flex flex-col justify-between;

  box-shadow: 0 4px 12px rgba(179, 181, 189, 0.12);

  .short-profile {
    @apply mr-2 text-base font-sans text-grey-mystery max-w-prose;
  }

  .coach-name {
    @apply text-2xl font-normal text-orange-vibrant;
  }

  .coach-headline {
    @apply text-base font-sans font-medium leading-tight text-navy-relaxed;
  }

  .see-availability {
    @apply mt-6 pt-3 pb-2 bg-orange-pensive block font-sans rounded-xl justify-self-end text-center text-lg text-navy-relaxed tracking-wider w-full hover:underline;
  }

  span.recommends-badge {
    @apply -ml-2.5 mb-2 px-5 py-1 text-sm font-sans text-navy-content bg-blue-cool rounded-full self-start whitespace-nowrap;
  }

  .button-secondary {
    @apply px-4 pt-2.5 pb-2 mt-2 text-blue-reflective text-center text-sm block w-fit rounded-xl border border-blue-reflective whitespace-nowrap;
  }
}

.profile-card-modal, .coach-profile-card-simple {
  span.recommends-badge {
    @apply -ml-2.5 mb-2 px-5 py-1 text-sm font-sans text-navy-content bg-blue-cool rounded-full self-start whitespace-nowrap;
  }

  .carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .carousel__viewport {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }

  .carousel__slide {
    min-width: 100%;
    box-sizing: border-box;
    text-align: center;
    align-content: center;
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .carousel__prev, .carousel__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .carousel__prev {
    left: 0;
  }

  .carousel__next {
    right: 0;
  }

  .carousel-multi {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .carousel__viewport-multi {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carousel__slide-multi {
    flex: 0 0 25%;
    box-sizing: border-box;
    padding: 0.2rem;
    text-align: center;
  }

  .logo {
    width: auto;
    max-height: fill-available;
    max-height: -moz-fill-available;
    max-height: -webkit-fill-available;
  }
}