.journey-carousel-wrapper {
  @apply grid items-center mt-6;

  @screen md {
    grid-template-columns: 3fr 2fr;
    grid-gap: 8rem;
  }
}

.journey-carousel {
  @apply hidden;

  min-height: 500px;
  padding: 100px 0;
  position: relative;

  @screen md {
    @apply flex;
  }

  li {
    width: 33%;

    .carousel-image {
      @apply border-sand bg-white overflow-hidden;

      border-width: 1px;
      border-radius: 20px;
      width: 100%;
    }
  }
}

.carousel-item {
  margin: 0 25px;
  opacity: 0.6;
  position: absolute;
  transform: translate(0, 0);
  transition: all 0.3s;
  cursor: pointer;

  &.--shadow:before {
    background-color: #fff;
  }

  &.--left {
    transform: translate(0, 0);
    z-index: 1000;

    .carousel-label {
      left: -10px;
    }
  }

  &.--focused {
    transform: translate(100%, 0) scale(1.5);
    z-index: 10000;
    opacity: 1;

    .carousel-label {
      right: 0;
    }
  }

  &.--right {
    transform: translate(200%, 0);
    z-index: 1000;

    .carousel-label {
      right: 0;
    }
  }
}

.carousel-label {
  @apply bg-orange-vibrant text-white px-2 py-1;

  position: absolute;
  bottom: 20px;
}

.border-gradient {
  display: grid;
  justify-items: flex-start;

  li {
    $border: 2px;

    @apply px-4 py-2 mb-1 bg-white text-navy-relaxed relative;

    background-clip: padding-box; /* !importanté */
    border: solid $border transparent; /* !importanté */
    border-radius: 20px;
    box-sizing: border-box;

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(269.23deg, #165275 5.75%, #F27D4D 91.24%);
    }
  }
}

.carousel-notes {
  @apply mb-4;
}

.home-journey-carousel {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
