.welcome-main-header {
  min-height: 3.5rem;
}

.welcome-menu {
  &.--with-background {
    background: url('../images/home/top-right-background.svg') no-repeat right top;
    background-size: contain;
    min-height: 10rem;
    position: relative;
  }

  svg {
    @apply mr-2 -mt-0.5 fill-navy-reliable;
  }
}

.welcome-mobile-menu-btn {
  @apply text-navy-reliable block fixed;

  height: 50px;
  right: 10px;
  top: 10px;
  width: 50px;
  z-index: 9999;

  @screen lg {
    @apply hidden;
  }
}

.welcome-nav-icon {
  @apply cursor-pointer relative w-7 h-7 mx-auto mt-4 text-navy-reliable;
  -moz-transform: rotate(0deg);
  -moz-transition: .5s ease-in-out;
  -o-transform: rotate(0deg);
  -o-transition: .5s ease-in-out;
  -webkit-transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transform: rotate(0deg);
  transition: .5s ease-in-out;

  span {
    @apply block absolute h-0.5 w-full bg-navy-reliable overflow-hidden;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 6px;
  }

  span:nth-child(3) {
    top: 6px;
  }

  span:nth-child(4) {
    top: 12px;
  }

  &.open {
    span:nth-child(1) {
      @apply bg-navy-reliable;
      left: 50%;
      top: 12px;
      width: 0;
    }

    span:nth-child(2) {
      @apply bg-navy-reliable;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      @apply bg-navy-reliable;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      @apply bg-navy-reliable;
      left: 50%;
      top: 12px;
      width: 0;
    }
  }
}
