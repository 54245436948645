$navy: #165275;

.coaching-session {
  @apply bg-navy-relaxed text-white;

  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  margin: 0 auto 0.25rem;
  max-width: 70%;
  overflow: visible;
  position: relative;
  text-align: center;
  transition: all 0.3s;

  form.booking-button {
    width: 100%;
  }

  &.--open {
    @apply bg-orange-vibrant;

    max-width: 100%;
  }

  .session-owner {
    display: none;
    color: #F27D4D;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 22px;
  }

  .session-reserved {
    display: none;
    color: #F27D4D;
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 22px;
  }

  &.--booked {
    @apply text-navy-relaxed border;

    background: #DFE2E1;
    border-color: rgba($navy, 0.1);
    display: none;

    a {
      cursor: not-allowed;
    }

    .session-starts-at {
      text-decoration: line-through;
    }

    &.--reserved {
      border: 2px solid #F27D4D;

      .session-reserved {
        display: inline;
      }
    }

    &.--owned {
      border: 2px solid #F27D4D;
      font-weight: 600;
      display: flex;

      .session-owner {
        display: inline;
      }
    }
  }
}

.booking-button {
  @apply w-full rounded-md;

  &:hover {
    @apply underline;
  }
}

@screen md {
  .coaching-session {
    &.--booked {
      display: flex;
    }
  }
}

.close-booking-option {
  background: rgba(255,255,255,0.1);
  border-radius: 50%;
  height: 30px;
  width: 30px;

  &:before {
    background: url("../images/cancel.svg") no-repeat center center;
    content: '';
    display: block;
    height: 23px;
    width: 30px;
  }

  &:hover {
    svg {
      background-color: red;
    }
  }
}
