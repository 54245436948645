.breadcrumbs {
  @apply text-white grid grid-cols-3;

  counter-reset: crumbNumber;
  font-size: 16px;
  padding-left: 0;
  position: relative;
  top: 10px;
  width: 100%;

  @screen md {
    @apply block;

    width: auto;
  }

  @screen lg {
    margin: 30px 0 80px;
  }

  &:before {
    background: #fff;
    bottom: 0;
    content: '';
    display: block;
    height: 6px;
    left: 20%;
    position: absolute;
    top: 23px;
    width: 60%;
  }

  @screen md {
    &:before {
      height: 80%;
      left: 0;
      top: 12px;
      width: 6px;
    }
  }

  li {
    counter-increment: crumbNumber;
    display: inline-block;
    height: 30px;
    margin: 15px 0;
    position: relative;

    .crumb-text {
      display: none;
    }

    @screen md {
      width: 30px;

      .crumb-text {
        display: block;
      }
    }

    &:before {
      @apply text-navy-relaxed font-bold pt-1;

      align-items: center;
      background: #fff;
      border-radius: 50%;
      bottom: 0;
      content: counter(crumbNumber);
      display: grid;
      height: 27px;
      left: calc(50% - 13px);
      position: absolute;
      text-align: center;
      top: -4px;
      width: 27px;
    }

    @screen md {
      &:before {
        height: 30px;
        left: -52px;
        width: 30px;
      }
    }

    &.--current {
      &:before {
        content: '';
      }

      &:after {
        background: #4DF27D;
        border-radius: 50%;
        bottom: 0;
        content: '';
        display: block;
        height: 10px;
        left: calc(50% - 5px);
        position: absolute;
        top: 5px;
        width: 10px;
      }

      @screen md {
        &:after {
          left: -42px;
          top: 6px;
        }
      }
    }

    &.--previous {
      &:before {
        @apply text-navy-relaxed font-bold pt-1;

        align-items: center;
        background: url("../images/breadcrumb-tick.svg") #4DF27D no-repeat center center;
        background-size: 16px;
        border-radius: 50%;
        bottom: 0;
        content: '';
        display: grid;
        height: 27px;
        left: calc(50% - 13px);
        position: absolute;
        text-align: center;
        top: -4px;
        width: 27px;
      }

      @screen md {
        &:before {
          background-size: 20px;
          left: -52px;
          width: 30px;
          height: 30px;
        }
      }

      &:after {
        background: #4DF27D;
        bottom: 0;
        content: '';
        display: block;
        height: 6px;
        left: 58%;
        position: absolute;
        top: 8px;
        width: 85%;
      }

      @screen md {
        &:after {
          height: 20px;
          left: -40px;
          top: 25px;
          width: 6px;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  @screen md {
    li {
      display: block;
      height: auto;
      margin: 15px 0;
      width: auto;
    }
  }
}

@screen md {
  .breadcrumbs {
    justify-self: center;
    padding-left: 40px;
    top: 0;
  }
}
