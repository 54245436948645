.calendar-card {
  @apply bg-sand overflow-hidden;

  @screen sm {
    @apply mb-2;
  }

  &__header {
    @apply
      bg-orange-vibrant
      grid
      grid-cols-[52px,1fr]
      items-start
      justify-items-start
      p-4
      pb-6;

    @screen sm {
      @apply justify-items-end;

      background: $gray url("../images/orange-day-cap.svg") no-repeat center bottom;
      background-size: cover;
      height: 160px;
    }

    .location {
      display: inline-block;
      position: relative;
      width: 18px;
      height: 22px;

      &:before {
        display: block;
        content: '';
        background: url("../images/location.svg") no-repeat center 4px;
        position: absolute;
        background-size: contain;
        width: 15px;
        height: 26px;
        top: 0;
      }
    }
  }

  &__body {
    @apply text-center px-5 -mt-4;
  }

  &.--month {
    .calendar-card__body {
      @apply hidden;

      @screen sm {
        @apply block;
      }
    }
  }
}
