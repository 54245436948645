@import url('https://fonts.googleapis.com/css2?family=Karma:wght@300;400;500;600;700&family=Lato:wght@300;400;700&family=Roboto:wght@400;700&display=swap');

@import 'sessions';
@import 'users';
@import 'general';
@import 'buttons';
@import 'links';

@import 'components/bookable_slot';
@import 'components/bookable_slot_information';
@import 'components/breadcrumbs';
@import 'components/calendar_card';
@import 'components/coach_image';
@import 'components/coach_profile_card_simple';
@import 'components/coach_selection';
@import 'components/context_menu';
@import 'components/faqs';
@import 'components/flashes';
@import 'components/header';
@import 'components/journey_carousel';
@import 'components/modal';
@import 'components/session_time';

@import 'pages/calendar';
@import 'pages/dashboards';
@import 'pages/launch';
@import 'pages/launch_event';
@import 'pages/post_feedback';
@import 'pages/confirmation';
@import 'pages/coaches';
@import 'pages/multi_page_forms/base';
@import 'pages/multi_page_forms/assessments';
@import 'pages/home/home';
@import 'pages/home/home-menu';

h1 {
  @apply text-3xl font-bold;

  color: #165275;
}

@screen md {
  h1 {
    @apply text-5xl;
  }
}

h2 {
  @apply text-2xl font-bold;

  color: #165275;
}

h3 {
  @apply text-xl font-bold;

  color: #165275;
}

.select {
  @apply p-4 rounded-lg text-base border;
}

.required {
  @apply text-orange-vibrant;
}

.booking-options {
  display: grid;
  margin-right: 7px;
  width: calc(50% + 15px);

  .button {
    @apply bg-white text-navy-relaxed rounded-2xl px-6;

    background: #fff url('../images/tick.svg') no-repeat 7px center;
    padding-left: 2.5rem;
  }
}

.unreserve-link {
  display: none;
}

.coaching-session.--reserved {
  .unreserve-link {
    display: block;
  }
}

.homepage-signup-fields {
  grid-template-columns: 1fr;

  @screen sm {
    grid-template-columns: 1fr auto;
  }
}

.social-links {
  display: flex;
  margin-top: 20px;

  a {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 40px;
    width: 40px;
    margin-right: 20px;

    &.--facebook {
      background-image: url('../images/facebook.svg');
    }

    &.--instagram {
      background-image: url('../images/instagram.svg');
    }

    &.--linkedin {
      background-image: url('../images/linkedin.svg');
    }

    &.--twitter {
      background-image: url('../images/twitter.svg');
    }
  }
}

.radial-progress-grandparent {
  --color: #fe4b0a;
  --track-color: #1f3a5e;
  --background: #fbefeb;
}

.radial-progress-parent {
  background: var(--background);
  color: #1f3a5e;
}

.radial-progress-container {
  display: flex;
  gap: 1em;
}

.radial-progress-child {
  height: var(--size, 100px);
  width: var(--size, 100px);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radial-progress-child::-webkit-progress-value {
  background: transparent;
}

.radial-progress-child::-moz-progress-bar {
  background: transparent;
}

.radial-progress-child:before {
  position: absolute;
  width: calc(var(--size, 100px) - var(--thickness, 10px) * 2);
  height: calc(var(--size, 100px) - var(--thickness, 10px) * 2);
  background-color: var(--background);
  color: #1f3a5e;
  margin: auto;
  content: attr(value) "%";
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@for $percent from 0 through 99 {
  .radial-progress-child[value="#{$percent}"]::-webkit-progress-bar {
    background: conic-gradient(
                    var(--color) 0% #{$percent}#{"%"},
                    var(--track-color) #{$percent}#{"%"} 100%
    );
  }
}
.radial-progress-child[value="100"]::-webkit-progress-bar {
  background: var(--color);
}