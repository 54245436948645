.calendar-results {
  @apply grid items-start;
}

.view-coach-profile-button {
  @apply text-center ml-2 py-2 px-4 text-navy-relaxed border border-navy-relaxed rounded-xl font-medium;

  @screen md {
    @apply px-10 ml-4;
  }
}

.change-coach-link {
  @apply underline text-navy-relaxed font-medium;
}

.calendar-results__heading {
  h1 {
    color: white;
  }
}

.filters {
  @apply grid gap-4 bg-sand mb-2;

  align-content: center;
  grid-template-columns: 50px 1fr;
  height: 70px;
  z-index: 99;

  @screen sm {
    height: auto;
  }
}

.coach-filters, .main-filters > .filter-title {
  @apply hidden;
}

.calendar-main {}

.calendar-dates {
  @apply pt-4 pb-1 border rounded-3xl hidden;
}

.session-finder__header {
  @apply grid py-4 items-center px-4 hidden;

  grid-template-columns: 3fr 1fr;
}

.session-finder__sessions {
  @apply grid gap-y-4;
}

@screen sm {
  .session-finder__sessions {
    @apply grid grid-flow-col auto-rows-fr;
  }
}

.session-finder-column {
  @apply pb-2;

  background: rgba(245,242,237,0.2);
  position: sticky;
  top: 10px;
}

.glide__slides {
  white-space: normal;
}

.coach-filter-list {
  @apply grid mb-2 text-center gap-2;

  grid-template-columns: repeat(5, 1fr);
}

.coach-filter-list__name {
  @apply text-xs text-grey-mystery self-start;
}

.timezone-switcher {
  @apply mt-2 mb-2;

  select {
    @apply text-xs;

    background-position: calc(100% - 15px) calc(1em - 2px);
    background-size: 10px;
  }

  input[type="submit"] {
    @apply text-xs p-2 rounded-md font-semibold;
  }
}

.position-sticky-fix {
  min-width: 0;
}

.coach-profiles-cta {
  @apply justify-self-center p-4 rounded-2xl border border-orange-pensive bg-orange-patient mb-2 mx-4;

  width: calc(100% - 2rem);

  .coach-image {
    margin-right: 5px;
  }
}

.coach-profiles-cta__link {
  @apply text-center w-full ml-2 py-2 px-4 text-navy-relaxed border border-navy-relaxed rounded-xl;

  @screen md {
    @apply px-10 ml-4;
  }
}

@screen md {
  .coach-profiles-cta {
    @apply justify-self-end;

    width: auto;

    .coach-image {
      margin-right: 5px;
    }
  }

  .calendar-results__heading {
    background: none;

    h1 {
      @apply text-navy-relaxed;
    }
  }

  .coach-filter-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .calendar-results {
    @apply mb-4;

    grid-template-columns: 3fr 2fr;
  }

  .filters {
    @apply hidden;
  }

  .coach-filters, .main-filters > .filter-title, .calendar-dates {
    @apply block;
  }

  .session-finder__header {
    @apply grid;
  }

  .calendar-main {
    @apply grid gap-8;

    grid-template-columns: 1fr 4fr;
  }

  .session-finder__sessions {
    @apply px-0;
  }

  .session-finder-column {
    @apply border border-orange-pensive;
    border-radius: 20px;
  }
}

#change_coach_modal {
  .actions {
    @apply grid grid-cols-1 gap-3;
  }

  p {
    @apply pb-3;
  }

  ol {
    @apply pb-3;

    li {
      @apply pl-5;
    }
  }
}
