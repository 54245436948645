@import 'app/assets/stylesheets/_vars';

#multi-page-form-container.--base {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    @extend .--style-hyperlinks;
  }

  .multi-page-form-button {
    color: white;
    background-color: #ef6637;
    padding: 5px 15px;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: bold;
    display: inline-flex;

    span.button-contents {
      display: inline-grid;
      grid-template-columns: auto auto;

      span.button-label {
        transform: translateY(2.5px);
        justify-self: start;
      }

      span.next-icon {
        $background-size: 18px;

        background-image: url('../images/icons/arrow-white-right.svg');
        background-position: 0px center;
        background-repeat: no-repeat;
        background-size: $background-size;
        padding-left: $background-size;
        margin-left: 5px;
      }
    }
  }

  .multi-page-form-button__back {
    $button-color: #b2b2b2;

    color: $button-color;
    border-radius: 5px;
    margin-bottom: 50px;
    display: inline-flex;

    span.button-contents {
      display: inline-grid;
      grid-template-columns: auto auto;
      padding: 6px 6px 6px 0px;

      &:hover {
        $hover-color: #9b9b9b;
        color: $hover-color;

        svg path {
          stroke: $hover-color;
        }
      }

      svg {
        transform: translateY(1.5px) scale(-1, 1);
        margin-right: 5px;

        path {
          stroke: $button-color;
        }
      }
    }
  }

  .button-hint {
    font-size: 14px;
  }

  .form-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas: "form-body form-image";

    .form-body {
      grid-area: form-body;
      margin: auto;

      .single-select-buttons-container {
        @apply mx-auto md:block md:flex gap-4 justify-center;

        .field-wrapper {
          @apply flex flex-col md:flex-row items-center justify-center mb-4 md:mb-0 relative;
        }

        .checkbox {
          @apply items-center justify-center h-16 md:h-[50px] w-full md:w-[50px] rounded-xl border-black border-2 cursor-pointer;

          &.selected {
            @apply bg-grey-7;
          }

          span {
            @apply inline-flex items-center justify-center h-full text-center;
          }
        }

        .value-text {
          @apply md:mt-2 text-sm text-center mx-auto;
        }

        .label {
          @apply absolute z-10 flex text-xl text-black font-bold;

          &.selected {
            @apply text-orange-patient;
          }
        }

        [type='checkbox'] {
          display: none;
        }

      }
    }

    .form-image {
      grid-area: form-image;
      width: 100%;
      height: auto;
      margin: auto;
    }
  }

  #after-form-paragraphs {
    #link-to-coach-directory p {
      text-align: right;
    }
  }

  @media only screen and (max-width: $tablet-width) {
    .form-container {
      grid-template-columns: 1fr;
      grid-template-areas:
        "form-image"
        "form-body";
      height: 100%;
    }
  }
}
