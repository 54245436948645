.bookable-slot-info {
  @apply divide-x;
  color: #165275;
  display: flex;
  line-height: 1.4;
  margin-bottom: 26px;

  &__coach {
    @apply pr-10;
    display: flex;
  }

  &__session-details {
    @apply pl-10;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
  }

  &__content {
    font-size: 20px;
    font-weight: 300;
  }
}
