.main-header {
  @apply bg-navy-relaxed;

  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 10rem;
}

.menu {
  &.--with-background {
    background: url('../images/top-right-background.svg') no-repeat right top;
    background-size: contain;
    min-height: 10rem;
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 60px;
      height: 54px;
      right: 3rem;
      bottom: -8px;
      background: url('../images/header-slabs.svg') no-repeat center center;
      background-size: contain;
    }
  }
}

.home-header {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 10rem;
}

@screen md {
  .main-header {
    background: none;
  }
}
