.launch-event-container {
  @apply mx-auto my-0 max-w-3xl;

  .email-validation-fields {
    grid-template-columns: 1fr;

    @screen sm {
      grid-template-columns: 1fr auto;
    }
  }

  .cta-button {
    @apply block max-w-xs text-3xl mt-4 mx-auto
  }
}
