@import 'app/assets/stylesheets/_vars';

.highlight-gradient-container {
  @apply bg-orange-patient rounded-2xl;
  display: grid;
  justify-items: flex-start;

  &.border-gradient {
    display: grid;
    justify-items: flex-start;

    & > div {
      $border: 2px;

      @apply w-full px-5 md:px-12 py-3 md:py-6 mb-1 mx-auto bg-white text-navy-relaxed relative;

      background-clip: padding-box; /* !importanté */
      border: solid $border transparent; /* !importanté */
      border-radius: 20px;
      box-sizing: border-box;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(269.23deg, #165275 5.75%, #f27d4d 91.24%);
      }
    }
  }
}

.orb-of-life-container, .ripple-effect-container {
  @apply px-2 md:px-5;

  h1 {
    @apply text-center;
  }

  .information_callout {
    @apply bg-orange-blissful text-navy-reliable;

    &.--sidebar {
      @apply -mx-7 md:mx-0 px-9 md:px-7 pb-8 pt-5 md:rounded-2xl md:shadow-lg;
    }

    &.--scorecard {
      @apply px-7 pb-8 pt-5 rounded-2xl shadow-lg;
    }

    h3 {
      @apply text-center text-base mb-5;
    }

    h4 {
      @apply font-bold;
    }

    p {
      @apply mb-3 font-sans;
    }

    ul {
      @apply mb-3 list-disc list-inside;
      li {
        @apply font-sans;
      }
    }
  }

  #orbOfLifeChart, #rippleEffectChart {
    g.bb-level {
      @apply fill-transparent stroke-grey-4 stroke-2;
    }

    .bb-arc {
      // colour fill needs to happen in bb.generate. Does nothing here
      @apply stroke-grey-4 opacity-90;
    }

    .bb-label-bg {
      @apply fill-navy-relaxed;
    }

    .bb-chart-arcs {
      text.bb-chart-indicator {
        @apply fill-white text-base font-sans;
      }
      text.bb-chart-label {
        @apply fill-black text-xs md:text-sm font-serif font-bold;
      }
      text.bb-chart-label-large {
        @apply fill-navy-relaxed text-xl font-serif font-bold;
      }
      text.bb-chart-label-emoji {
        @apply fill-navy-relaxed text-3xl font-serif font-bold;
      }
      .bb-chart-grid {
        @apply fill-transparent fill-transparent stroke-grey-4;
      }
    }
  }
}

#multi-page-form-container.--orb-of-life-assessment, #multi-page-form-container.--ripple-effect-assessment {
  h1 {
    @apply mb-5 text-5xl text-orange-vibrant text-center;
  }

  h2 {
    @apply mb-5 text-orange-vibrant text-center;
    font-size: 2.5rem;
    line-height: 2.75rem;
  }

  h3 {
    @apply mb-5 text-5xl text-black text-center leading-10;
  }

  h4 {
    @apply mb-5 text-grey-7 text-center font-bold leading-9;
    font-size: 2.5rem;
    line-height: 2.75rem;
  }

  p {
    @apply text-xl font-sans text-center;
    @extend .--style-hyperlinks;
  }

  #orb-of-life-form {
    @apply md:h-[80vh];
  }

  form {
    @apply h-full flex flex-col;
  }

  .multi-page-form-button {
    display: none;
  }

  .multi-page-form-button__back {
    display: none;
  }

  .button-hint {
    display: none;
  }

  .form-container {
    @apply w-full mx-auto;

    .form-body {
      .single-select-buttons-container {
        @apply mx-auto md:block md:flex gap-4 justify-center;

        .field-wrapper {
          @apply flex flex-col md:flex-row items-center justify-center mb-4 md:mb-0 relative;
        }

        .checkbox {
          @apply items-center justify-center h-16 md:h-[50px] w-full md:w-[50px] rounded-xl border-black border-2 cursor-pointer;

          &.selected {
            @apply bg-grey-7;
          }

          span {
            @apply inline-flex items-center justify-center h-full text-center;
          }
        }

        .value-text {
          @apply md:mt-2 text-sm text-center mx-auto;
        }

        .label {
          @apply absolute z-10 flex text-xl text-black font-bold;

          &.selected {
            @apply text-orange-patient;
          }
        }

        [type='checkbox'] {
          display: none;
        }
      }

      [type='text'] {
        @apply min-w-0 flex-auto text-2xl border-t-0 border-x-0 text-center px-3.5 py-2 text-navy-relaxed ring-1 ring-inset ring-white focus:ring-2 focus:ring-inset focus:ring-white focus:border-orange-vibrant border-b-2 border-navy-relaxed;
      }

      .single-select-container {
        @apply max-w-md mx-auto;

        .field {
          @apply pb-3.5 flex items-center text-xs whitespace-nowrap;
        }

        input {
          @apply h-6 w-6 mr-2 rounded-full border-navy-relaxed text-blue-reflective focus:ring-blue-reflective;
        }
      }
    }

    .form-navigation {
      @apply mx-auto;

      .--to-home {
        svg {
          @apply stroke-grey-3;
        }
      }

      .button_cta {
        @apply px-12 pt-4 pb-3.5 text-center text-2xl block w-fit rounded-2xl whitespace-nowrap hover:underline disabled:bg-grey-6 disabled:text-grey-2;

        &.primary-cta {
          @apply text-navy-relaxed bg-orange-blissful;
        }

        &.secondary-cta {
          @apply text-blue-reflective border-2 border-blue-reflective;
        }
      }
    }

    .form-graphic {
      @apply relative mx-auto max-w-xl;
    }
  }

  .full-view {
    @apply p-2;

    .small-arrows {
      @apply sm:w-1/12;

      img {
        @apply px-2 h-7;
      }
    }

    .progress {
      @apply mr-auto w-1/3 sm:mr-auto;
    }
  }

  #after-form-paragraphs {
    #link-to-coach-directory p {
      text-align: right;
    }
  }
}
