@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import '@glidejs/glide/dist/css/glide.core.min.css';

.right-panel__main {
  @apply p-4 grid;

  align-content: center;
}

@screen md {
  .right-panel__main {
    max-width: 700px;
    padding-left: 10%;
  }
}

body.onboarding, body.magic_links {
  .right-panel__main {
    @apply px-4;

    max-width: 550px;

    @screen md {
      @apply px-8;

      padding-left: 10%;
    }
  }
}

ul.principles {
  li {
    @apply mb-4 pl-20 py-4;

    background-repeat: no-repeat;
    background-position: left center;

    &.confidential {
      background-image: url('../images/confidential.svg');
    }

    &.good-listener {
      background-image: url('../images/good-listener.svg');
    }

    &.safe-space {
      background-image: url('../images/safe-space.svg');
    }
  }
}

.user-details__email {
  justify-self: flex-start;
  position: relative;

  &:before {
    @apply font-sans;

    border-radius: 6px;
    color: #fff;
    display: block;
    font-size: 8px;
    font-weight: bold;
    left: calc(100% + 10px);
    letter-spacing: 1px;
    line-height: 2.5;
    padding: 0 8px;
    position: absolute;
    text-transform: uppercase;
    top: 2px;
  }

  &.--primary:before {
    @apply bg-orange-vibrant;

    content: 'Primary';
  }
}

a.icon-menu {
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 26px;
  background-position: left top;

  &:hover {
    text-decoration: underline;
  }

  &.--home {
    background-image: url('../images/icons/material-icons/home_outlined_24px.svg');
  }

  &.--details {
    background-image: url('../images/icons/details.svg');
  }
}

.--slabs-left {
  position: relative;

  &:before {
    background-image: url('../images/homepage-slabs.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: ' ';
    display: block;
    height: 55px;
    left: -120px;
    position: absolute;
    top: 65px;
    width: 60px;
  }
}

.sign-up-form {
  @apply mt-6 p-5;
}

.footer-sign-up {
  background-image: url('../images/footer-sign-up-threads.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;

  .sign-up-form {
    @apply m-0 p-0;
  }
}

.terms-and-conditions {
  @apply block p-4 rounded-xl w-full cursor-pointer select-none;

  border: solid 1px rgba(0,0,0,0.1);

  input[type="checkbox"] {
    @apply mr-3;
  }

  a {
    @apply text-orange-vibrant underline;
  }
}
