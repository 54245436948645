.coach-image {
  border-radius: 7px;
  display: inline-block;
  height: 63px;
  margin-right: 10px;
  overflow: hidden;
  width: 38px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &.--session-card {
    border: solid 1px white;
    height: 90px;
    width: 55px;
  }
}

.coach-filter {
  grid-template-rows: auto 30px;

  .coach-image {
    height: auto;
    margin: 0;
    width: auto;
  }

  label {
    cursor: pointer;
    display: block;
    height: 100%;
    text-align: center;
  }
}

.main-filters {
  .coach-image {
    margin-right: 0;
  }
}
