.bookable_slot_confirmation {
  &__title {
    @apply font-semibold text-2xl mb-8 mt-8
  }

  &__actions {
    @apply flex space-x-6 mt-8
  }

  &__cancel_button {
    @apply w-40 text-xl text-center border rounded-full p-1 pt-2 border-black bg-white
  }

  &__confirm_button {
    @apply w-40 text-xl text-center border rounded-full p-1 pt-2 border-orange-vibrant bg-orange-vibrant text-white
  }
}
