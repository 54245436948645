.modal {
  display: none;

  &.--open {
    display: grid;
  }

  &.--small {
    .modal-container {
      max-width: 600px;
    }
  }

  &.--large, &.--wide {
    .modal-container {
      max-width: 1200px;
    }
  }
}

.modal-background {
  align-content: center;
  align-items: center;
  background: rgba(0,0,0,0.6);
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.modal-container {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  display: grid;
  margin: 0 auto;
  height: 100%;
  max-height: 95vh;
  max-width: 800px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  width: calc(100% - 20px);

  @screen sm {
    padding: 30px;
    width: 90%;
  }
}

.modal-content {
  height: 100%;
  color: black;
}

.modal-close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  svg {
    fill: gray;
  }
}
