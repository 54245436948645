.bookable-slot {
  @apply grid mb-2 bg-sand rounded-xl;

  align-items: center;
  background-image: url("../images/orange-cap-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25% 100%;
  grid-template-columns: 60px 1fr;

  .coach-and-location {
    flex-direction: row-reverse;
    justify-content: flex-end;
    display: none;
  }

  .coach-image {
    margin-left: 0;
  }

  .session-list {
    margin: 0;
    width: 100%;
  }
}

.bookable-slot__details {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
}

@screen md {
  .bookable-slot {
    @apply grid-cols-2;

    background-size: 60% 100%;

    .coach-and-location {
      display: flex;
    }
  }

  .bookable-slot__details {
    grid-template-columns: 60px 1fr;
  }
}
