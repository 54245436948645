.button {
  @apply text-white bg-orange-vibrant text-center rounded-xl py-4 px-12 inline-block;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.--rounded {
    @apply px-12 py-2 rounded-3xl;
  }

  &.--icon {
    @apply pl-10 pr-4 pb-1 relative;

    background-image: url('../images/icons/notifications.svg');
    background-position: 16px center;
    background-repeat: no-repeat;
    background-size: 18px;
  }

  &.--secondary {
    @apply bg-navy-relaxed px-5 pb-1;
  }

  &.--tertiary {
    @apply px-4 py-1 bg-navy-relaxed bg-opacity-80 text-sm border-opacity-100 border-navy-relaxed border-solid border;

    &:hover {
      @apply underline;
    }
  }

  &.--hollow {
    @apply bg-transparent border-navy-relaxed border-solid border text-navy-relaxed;
  }

  &.--multiple {
    @apply bg-sand p-3 flex text-navy-relaxed text-left;

    &:hover {
      @apply underline;
    }
  }

  &.--booking-cta {
    border-radius: 40px;
    display: inline;
  }

  &.--disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &.--square {
    @apply py-4 px-2 text-lg;
  }

  &.--full {
    width: 100%;
  }

  &.--booking-cta {
    @apply rounded-3xl py-4 px-12 inline;
  }
}

body.appointments.calendar, body.bookable_slots.confirm {
  .button.--booking-cta {
    visibility: hidden;
  }
}
