body {
  @apply font-serif;

  font-smooth: antialiased;
}

.panels-container {
  @screen md {
    grid-template-columns: 2fr 3fr;
  }
}

.bg-orange-patient {
  background-color: rgba(245, 242, 237, 0.2);
}

.left-panel {
  @apply bg-transparent;

  align-self: flex-start;
  grid-auto-rows: auto 1fr;

  @screen md {
    border-bottom-right-radius: 60px;
    min-height: 100%;
  }
}

.mobile-menu-btn {
  @apply bg-orange-vibrant text-white border rounded-full block uppercase text-center fixed;

  box-shadow: 0 4px 22px 0 rgb(0 0 0 / 20%);
  font-family: Roboto, sans-serif;
  font-size: 10px;
  height: 50px;
  right: 10px;
  top: 10px;
  width: 50px;
  z-index: 9999;

  @screen lg {
    @apply hidden;
  }
}

.nav-icon {
  -moz-transform: rotate(0deg);
  -moz-transition: .5s ease-in-out;
  -o-transform: rotate(0deg);
  -o-transition: .5s ease-in-out;
  -webkit-transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  cursor: pointer;
  height: 18px;
  margin: 10px auto 0;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 20px;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 1px;
    overflow: hidden;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 6px;
  }

  span:nth-child(3) {
    top: 6px;
  }

  span:nth-child(4) {
    top: 12px;
  }

  &.open {
    span:nth-child(1) {
      left: 50%;
      top: 12px;
      width: 0;
    }

    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      left: 50%;
      top: 12px;
      width: 0;
    }
  }
}

.mobile-menu {
  @apply bg-orange-blissful font-serif text-navy-reliable;

  bottom: 0;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  @screen md {
    background-size: 250px;
  }

  ul {
    margin-left: 25px;
    padding-top: 20px;
  }

  li {
    display: flex;
    margin-bottom: 25px;

    svg {
      @apply fill-navy-reliable block;
      margin-right: 15px;
    }
  }
}

body.users {
  .left-panel__main {
    @apply grid;
  }
}

.left-panel__main {
  @apply px-4 pb-4 hidden;

  grid-template-rows: 1fr auto;
  justify-self: center;

  @screen sm {
    @apply grid;
  }

  @screen md {
    @apply px-4 pt-16;

    background: none;
    bottom: 40px;
    max-width: 450px;
    position: static;
  }
}

body.onboarding {
  .left-panel {
    background-color: transparent;
  }

  .right-panel-header {
    display: grid;
    grid-template-rows: auto 1fr;
    padding-bottom: 75px;
  }

  .left-panel__main {
    bottom: 0;
    display: grid;
    justify-self: center;
    left: 0;
    position: fixed;
    right: 0;

    @screen md {
      position: static;
    }
  }
}

.left-panel-content {
  max-width: 500px;
  width: 100%;

  @screen md {
    @apply pt-6;
  }
}

.--shadow {
  position: relative;

  &:before {
    @apply rounded-3xl border border-orange-pensive;

    display: block;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    left: -7px;
    top: 7px;
  }
}

.--orb {
  @apply text-white;

  background: #165275 url("../images/session-card-orb.svg") no-repeat left top;
  background-size: contain;
}

@screen md {
  .md\:grid-cols-2-1 {
    grid-template-columns: 2fr 1fr;
  }

  .md\:grid-cols-3-2 {
    grid-template-columns: 3fr 2fr;
  }
}

.error {
  margin-top: 12px;
  color: #cc0000;
}

input:focus, select:focus {
  box-shadow: none;
  outline: none;
}

.photos {
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;

    background: #F27D4D;
    border-radius: 5px;
    bottom: 60px;
    height: 28px;
    opacity: 0.75;
    transform: rotate(1.94deg);
    width: 28px;
    z-index: 10;
    left: -40px;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;

    background: #F27D4D;
    border-radius: 5px;
    bottom: 20px;
    height: 28px;
    opacity: 0.5;
    transform: rotate(1.94deg);
    width: 28px;
    z-index: 10;
    left: -40px;
  }

  &.--with-outline {
    .photo {
      &.--front {
        position: relative;
        bottom: 10px;
        left: 10px;
      }

      &.--back {
        @apply border-orange-vibrant border;
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }
  }

  &.--with-rotation {
    .photo {
      &.--front {
        transform-origin: bottom left;
        transform: rotate(-1.94deg);
        position: relative;

        &:before {
          display: block;
          content: '';
          position: absolute;

          background: #F27D4D;
          border-radius: 10px;
          bottom: 30px;
          height: 50px;
          opacity: 0.75;
          transform: rotate(1.94deg);
          width: 50px;
          z-index: 10;
          left: 15px;
        }
      }

      &.--back {
        background: #E8E6DC;
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }
  }
}

.photo {
  border-radius: 25px;
  overflow: hidden;

  &.--with-outline {

  }
}

.examples-list {
  li {
    @apply bg-sand text-navy-relaxed text-center my-1 py-2 px-6 border-l border-10 border-orange-vibrant;

    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
  }
}

select {
  background-color: white;
  //border: thin solid #FCE5DB;
  border: thin solid rgba(0,0,0,0.1);
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url("../images/expander-arrow.svg");

  background-position:
          calc(100% - 15px) calc(1em + 2px),
          calc(100% - 10px) calc(1em + 2px),
          calc(100% - .5em) .5em;

  background-size: 15px;

  background-repeat: no-repeat;
}

/* Hide IE banner by default */
.ie-notice {
  display: none;
  background: orange;
  color: black;
  font-size: 22px;
  font-weight: 600;
  left: 0;
  padding: 30px 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999;

  &__button {
    text-decoration: underline;
  }
}

/* Show IE banner on IE10/11 */
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
  .ie-notice {
    display: block;

  }
}

