.flash-list {
  display: grid;
  justify-items: center;
  left: 50%;
  max-width: 800px;
  padding: 0 20px;
  position: fixed;
  text-align: center;
  top: 15px;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9999999;

  li {
    border-radius: 10px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 10px;
    max-width: 800px;
    padding: 10px;
    width: 100%;

    .flash-message {
      padding: 10px;
      display: grid;
      align-items: center;
    }

    &.--notice, &.--info {
      background: #4DF27D;
      color: #165275;

      .mask, .wrapper:after {
        background: rgba(77, 242, 125, 0.3);
      }
    }

    &.--alert, &.--error {
      background: #F24D4D;
      color: #FFFFFF;

      .mask, .wrapper:after {
        background: rgba(242, 77, 77, 0.3);
      }
    }
  }

  .flash-close {
    align-items: center;
    background: rgba(255, 255, 255, 0.3) url("../images/close.svg") no-repeat center center;
    border-radius: 50%;
    border: solid 3px #fff;
    cursor: pointer;
    display: grid;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
}
