.home-container {
  @apply max-w-8xl px-4 md:mx-auto;
}

.home-title {
  @apply text-2xl font-grenette text-navy-relaxed text-center;

  @screen md {
    @apply leading-10;
    font-size: 2rem;
  }
}

.home-subtitle {
  @apply text-xl text-navy-relaxed font-bold text-center;

  @screen md {
    @apply leading-10;
    font-size: 1.5rem;
  }
}

.home-rail-title {
  @apply mb-1 md:mb-2 text-navy-relaxed font-grenette;
  font-size: 1.75rem;
}

.home-rail-subtitle {
  @apply mb-2 md:mb-5 text-navy-relaxed font-normal;
  font-size: 1.25rem;
}

.partial-underline {
  @apply mb-2 border-b border border-navy-relaxed w-20 h-0;
}
.button-cta-large {
  @apply pt-4 pb-3 md:px-4 w-full block rounded-xl text-2xl text-center hover:underline;

  &.--primary {
    @apply bg-orange-pensive text-navy-reliable;
  }
  &.--secondary {
    @apply bg-none text-blue-reflective border-2 border-blue-reflective;
  }
}
.home-coaching {
  .button {
    @apply pt-3 pb-2 px-8 md:px-6 rounded-3xl text-navy-relaxed bg-orange-pensive text-2xl;
  }

  .coaching-explainer {
    font-size: 1.125rem;
  }

  .coaching-rail {
    .card {
      @apply bg-white mb-4 p-3 md:p-4 rounded-2xl w-full h-full shadow-md overflow-hidden border border-grey-5 hover:shadow-none transition duration-500;

      .assessment, .assignment {
        .text {
          @apply leading-tight text-navy-relaxed text-base h-full inline-flex items-center;
        }
      }

      &.--dotted {
        @apply border border border-dashed border-black;

        .text {
          @apply leading-tight text-navy-relaxed text-base h-full inline-flex items-center;
        }

        svg {
          @apply w-6 h-6 text-navy-reliable mr-0;
        }
      }

      .button {
        @apply pt-2.5 pb-2 block rounded-xl text-center text-lg tracking-wider text-navy-relaxed bg-orange-pensive w-full hover:underline;

        &.--icon {
          @apply fill-navy-reliable;
        }
      }

      .date-label {
        @apply whitespace-normal lg:whitespace-nowrap leading-5;
        font-size: 1.125rem;
      }
    }
  }

  .button-secondary {
    @apply px-4 pt-2.5 pb-2 mt-2 text-blue-reflective text-center text-sm block w-fit rounded-xl border border-blue-reflective whitespace-nowrap;

    @screen md {
      @apply underline border-0 px-0 py-0;
      font-size: 1.125rem;
    }
  }

  .header {
    @apply font-bold text-xl text-navy-relaxed leading-tight;

    @screen md {
      font-size: 1.9rem;
    }
  }

  svg {
    @apply fill-navy-relaxed inline-block;
  }

  .coach-headline {
    @apply font-medium leading-tight text-navy-relaxed pb-1;
  }

  .profile-card-modal {
    @apply w-0 h-0;

    .short-profile {
      @apply font-sans text-grey-mystery;
    }

    .coach-name {
      @apply pb-1 text-3xl text-orange-vibrant;
    }

    .see-availability {
      @apply bg-orange-vibrant block font-semibold mt-6 p-3 rounded-2xl self-end text-center text-lg text-white tracking-wider w-full hover:underline;
    }
  }
}

.home-faq-list {
  @apply text-navy-relaxed;

  .faq-item {
    @apply pt-7 pb-8 pr-14 mb-2 bg-orange-blissful rounded-xl shadow-sm;

    .faq-header {
      @apply text-xl pl-14;
      position: relative;
      cursor: pointer;

      &:before {
        background: url('../images/expander-arrow.svg') no-repeat center center;
        content: '';
        display: block;
        height: 20px;
        left: 32px;
        right: 12px;
        position: absolute;
        top: 2px;
        transform: rotate(-90deg);
        transition: all 0.3s;
        width: 20px;
      }
    }
  }

  .faq-item.--open .faq-header:before {
    transform: rotate(0deg);
  }

  .faq-answer {
    @apply pl-14 mt-2;
    p {
      @apply mt-3 text-xl;
    }
  }
}

.blog-nav {
  @apply lg:fixed block px-2 pb-4 md:pb-0 mx-auto md:max-w-xs; }

.blog-nav a {
  @apply pb-1.5 hover:underline text-navy-relaxed hover:text-blue-reflective font-sans;
}

.modal-left-background {
  background-color: rgb(250 239 235);
}

.content {
  blockquote { @apply p-2 text-lg border-l-4 border-blue-calm; }
  ul { @apply list list-disc list-inside mb-4 ml-2; }
  ol { @apply list list-decimal list-inside mb-4 ml-2; }
  a { @apply underline text-blue-open hover:text-blue-reflective; }
}
