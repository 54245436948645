$black: #000;
$dark-grey: #C4C4C4;
$gray: #F5F2ED;
$light-grey: #F3F3F3;

input[type="submit"] {
  cursor: pointer;
}

.sessions-nav {
  display: flex;

  .button {
    @apply bg-white border border-navy-relaxed grid text-navy-relaxed items-center justify-center rounded-3xl;

    margin-right: 5px;
    min-width: 115px;
    padding: 6px 13px;

    a:hover {
      text-decoration: underline;
    }

    &.glide__arrow--disabled {
      cursor: not-allowed;
      opacity: 0.2;

      a:hover {
        text-decoration: none;
      }
    }

    &.--forwards {
      grid-template-columns: 1fr auto;
      padding-right: 5px;

      .direction {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &.--backwards {
      grid-template-columns: auto 1fr;
      padding-left: 5px;
    }
  }

  .direction {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    height: 30px;
    position: relative;
    width: 30px;

    svg {
      display: block;
      fill: #165275;
      height: 20px;
      left: 10px;
      position: absolute;
      top: 5px;
      width: 10px;
    }
  }
}

.glide__slide {
  border-radius: 20px;
}

.booking-date {
  @apply border border-white p-2 rounded-md text-center text-white bg-orange-vibrant;
}

.day-details {
  @apply flex;
}

.coach-and-location {
  @apply flex flex-row-reverse text-white;
}

@screen md {
  .day-details {
    @apply grid justify-end justify-items-end;
  }

  .coach-and-location {
    @apply flex-row;
  }
}

.show-sessions {
  @apply text-white underline md:pl-4;

  position: relative;

  &:before {
    background-size: 15px 13.5px;
    background: url('../images/expander-arrow-white.svg') no-repeat center center;
    content: '';
    display: inline-block;
    height: 15px;
    position: relative;
    transition: all 0.5s;
    transform-origin: center;
    transform: rotate(0);
    width: 20px;
  }
}

.fully-booked-panel {
  @apply text-black items-center rounded-3xl grid justify-center p-6 md:px-10 md:py-24 text-center bg-sand mt-8 border;
}

.glide__slide.--closed {
  .show-sessions:before {
    transform: rotate(-90deg);
  }
}

.session-list {
  @apply mb-2 px-4 mx-auto pt-8;

  @screen sm {
    @apply pt-4;
  }

  .button {
    border-radius: 7px;
    padding: 6px 0 3px;
  }

  .session-option {
    display: block;

    flex-grow: 1;
    padding: 10px 15px;
  }

  @screen sm {
    @apply pt-0;
  }
}

.jump-calendar {
  @apply bg-sand;

  color: #424242;
  left: 0.5rem;
  position: relative;

  .jump-link {
    display: block;

    left: 1px;
    position: relative;
    top: 1.5px;
  }

  .dates {
    display: grid;
    grid-auto-rows: 35px;
    grid-gap: 3px;
    grid-template-columns: repeat(5, 40px);
    margin-top: 12px;
    text-align: center;
  }

  .date {
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    &.--no_booking .content,
    &.--office_contract_terminated .content,
    &.--unbookable .content {
      background: #fff;
      border: solid 1px rgba(22,82,117,0.6);
      cursor: default;
      height: 10px;
      width: 10px;
    }

    &.--unbookable_with_appointment .content,
    &.--fully_booked .content {
      opacity: 0.5;
    }

    .content {
      background: #165275;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      height: 35px;
      line-height: 35px;
      overflow: hidden;
      width: 35px;
    }
  }
}

.search-form {
  .--disabled {
    opacity: 0.3;
  }
}

#session-filters-modal {
  &:before {
    border-bottom: 12px solid rgba(243, 244, 246, 1);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: '';
    display: none;
    height: 0;
    left: calc(50% - 6px);
    position: absolute;
    top: -12px;
    width: 0;
  }
}

@screen md {
  #session-filters-modal:before {
    display: none;
  }
}

.sticky {
  box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
  position: fixed;
  top: 0;
  width: 100%;
}

.coach-profiles-cta.stuck {
  margin-top: 70px;
}

@screen md {
  .sticky {
    position: static;
    top: 0;
    width: auto;
  }
}

#session-filters {
  .filters-popover {
    @apply rounded-2xl z-50 bg-white p-4 shadow fixed;

    left: 10px;
    right: 10px;
    top: 70px;
  }

  #modal-search-mask {
    background-color: rgba(0,0,0,0.3);
  }

  .modal-search {
    display: none;

    .dow, .time_of_day {
      @apply bg-navy-relaxed text-white;
    }
  }

  &.--open {
    .modal-search {
      display: block;
    }
  }

  @screen md {
    .filters-popover {
      @apply absolute mt-3 border border-orange-pensive;

      left: calc(-50% - 38px);
      max-width: 600px;
      top: 10px;
      width: 400px;
    }

    #modal-search-mask {
      background-color: transparent;
    }
  }
}

.glide {
  overflow: hidden;
}

.dow {
  @apply bg-navy-relaxed grid text-white overflow-hidden items-center justify-center;

  border-radius: 50%;
  border: solid 1px rgba(0,0,0,0.3);
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  width: 40px;

  &.--inert {
    background: gray;
    cursor: not-allowed;
    opacity: 0.1;
  }
}

.off-screen {
  left: -999999px;
  position: absolute;
}

.time_of_day {
  @apply bg-navy-relaxed text-white;

  cursor: pointer;
  padding: 8px 20px;

  &.--am {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &.--pm {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: none;
  }
}

.session-card {
  grid-template-columns: 1fr 1fr;

  &.--no-options {
    grid-template-columns: 1fr;

    .session-card__options {
      display: none;
    }
  }
}

.past-sessions {
  .session-card {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-image: url('../images/past-session-tick.svg');
    background-repeat: no-repeat;
    background-position: right calc(100% - 0.5rem);
  }
}

.toggle-filters {
  display: block;
  height: 100%;
  width: 100%;
  background: url("../images/filter.svg") no-repeat center center;
  background-size: 20px;
}

.thread {
  height: 200px;
  width: 100%;

  &.--bookings {
    background: url("../images/threads/bookings.svg") no-repeat 30% center;
  }
}

.large-session-card {
  @apply bg-sand;

  background-image: url("../images/orange-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 60px 60px 120px 1fr;
  grid-gap: 5px;
  align-items: center;
  align-content: center;
}

.upcoming-sessions-list {
  li {
    position: relative;
  }
}

.day-card {
  @apply bg-sand rounded-2xl text-white overflow-hidden pb-4;
}

.day-card-header {
  @apply p-4;

  background-image: url("../images/small-day-cap.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.large-coach-image {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.calendar-links {
  .button {
    padding: 10px;
  }
}
