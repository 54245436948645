.launch-container {
  max-width: 1000px;
  padding: 20px;
  margin: 0 auto;

  h1 {
    font-size: 32px;
    margin-bottom: 30px;
    color: #165275;
  }

  .launch-content {
    @apply bg-sand rounded-xl;

    font-size: 20px;
    color: #165275;
    padding: 30px;
    display: flex;
    flex-direction: column;

    &.hidden {
      display: none;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
    }

    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
    }

    &__bullet-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-basis: 130px;
      margin: 40px 0 0;
    }

    &__bullet {
      display: flex;
      flex-direction: column;
      flex-basis: 190px;
      align-items: center;
      text-align: center;
      justify-content: space-between;

      i {
        display: flex;
        justify-content: center;
        font-size: 40px;
        color: #1D6793;
      }
    }

    &__disclaimer {
      margin-top: 20px;
      font-size: 18px;
      align-self: center;
    }

    &__cta-wrapper {
      display: flex;
      align-self: center;
      flex-direction: column;
      position: relative;
      margin-top: 40px;
      width: 100%;
    }

    &__tooltip {
      position: absolute;
      background: #165275;
      border-radius: 8px;
      top: -100%;
      right: -10%;
      color: #fff;
      text-align: center;
      max-width: 450px;
      padding: 5px 10px;
      font-size: 16px;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.33s linear 0s, opacity 0.33s linear;

      &.tooltip-show {
        visibility: visible;
        opacity: 1;
      }
    }

    &__tooltip:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 20%;
      width: 0;
      height: 0;
      border: 18px solid transparent;
      border-top-color: #165275;
      border-bottom: 0;
      border-left: 0;
      margin-left: -9px;
      margin-bottom: -18px;
    }

    .button.launch-session {
      width: 300px;
      align-self: center;
      font-size: 28px;

      &.--disabled {
        opacity: 0.9;
        background-color: #dfe2e1;
      }
    }

    .button.book-session {
      width: 320px;
      align-self: center;
      font-size: 28px;
    }

    .button.manager-button {
      @apply bg-blue-reflective
    }

    .button.manager-button:hover {
      @apply bg-navy-relaxed
    }

    .dashboard-link {
      margin-top: 20px;
      text-decoration: underline;
      align-self: center;
    }
  }
}
