.faq-list {
  @apply text-navy-relaxed;
}

.faq-item {
  margin-bottom: 2px;

  &:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .faq-header {
    position: relative;
    cursor: pointer;

    &:before {
      background: url("../images/expander-arrow.svg") no-repeat center center;
      content: '';
      display: block;
      height: 20px;
      left: 2px;
      position: absolute;
      top: 2px;
      transform: rotate(-90deg);
      transition: all 0.3s;
      width: 20px;
    }
  }
}

.faq-item.--open .faq-header:before {
  transform: rotate(0deg);
}

.faq-answer {
  p {
    @apply my-3;
  }
}
